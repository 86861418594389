.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* max-height: 95vh; */

  text-align: center;
  background-color: #f0f0f0;
  padding: 20px;
  gap: 5rem;
}

.title {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

.image {
  width: 250px; /* Adjust the size of the image as needed */
  height: auto;
  
  padding: 15px;
  transition: transform 0.3s ease-in-out;
  animation: pulse 2s infinite;
}

.image:hover {
  transform: scale(1.05);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
