.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navbar-height);
  padding: 2%;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10 ;
}