.footerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  background-color: #fff;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 50px;
}

.detailsWrapper {
  display: flex;
  align-items: center;
  width: 60%;
  padding-left: 5%;
}

.detailsLink {
  color: #ff4500;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}

.checkoutButton {
  padding: 10px 20px;
   background: linear-gradient(135deg,  #ff4c29 1%,#eb1c24 100%);
  color: white;
  border: none;
  border-radius: 0;
  width: 40%;
 text-decoration: none;
  text-align: center;
  height: 100%;
 
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.checkoutButton:hover {
  background-color: #e03e00;
  color: #fff;
}
.checkoutButton:focus{
  outline:none;
}




