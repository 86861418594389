
.bodyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0% 3%;

}

.btnWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
  width: 100%;
  background: #f5f5f5;
  height: var(--btn-wrapper-height);
}

.orderTypeBtn {
  border: none;
  background: #fff;
  width: 48%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2%;
}

.orderTypeBtn span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-button-bg);
  /* background: linear-gradient(135deg,  #ff4c29 1%,#eb1c24 100%); */
}

.orderTypeBtn:focus {
  outline: none;
}

.orderTypeBtn img {
  height: 50px;
  width: 50px;
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 0px;
  background-color: #fff;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  bottom: 40px;
  width: 100%;
}

.detailsWrapper {
  display: flex;
  align-items: center;
  width: 60%;
}

.detailsLink {
  color: #ff4500;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}

.checkoutButton {
  padding: 10px 20px;
  background-color: #ff4500;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.checkoutButton:hover {
  background-color: #e03e00;
}


@media (min-width: 900px) {
  /* .customerWrapper {
    width: 60%;
    background: #f5f5f5;
  } */
}

@media (max-width: 576px) {
  .orderTypeBtn span {
    font-size: 1rem;
    width: 50%;
  }

  .orderTypeBtn img {
    height: 40px;
    width: 40px;
  }
}
