.foodOrderWrapper {
 display: flex;
 flex-direction: column;
 height: 100%;
  background-color: #f8f8f8;
  width: 100%;
  
}

.inputWrapper{
  display: flex;
height: var(--input-wrapper-height);
}

.searchInput {
  width: 100%;
    padding: 10px;
    box-shadow: 0px 0px 2px 2px #ddd;
    border: none;
}

.searchInput:focus{
  outline: none;
}

.clearButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.8em;
  margin-left: -30px;
  
  color: #aaa;
}
.clearButton:focus{
  outline: none;
}

.clearButton:hover {
  color: #000;
}


.remarkButton {
background: none;
  color: var(--primary-button-bg);
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
}

.remarkButton:focus{
  outline: none;
}

.remarkWrapper {
   margin-top: 5px;
}

.remarkInput {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.remarkInput:focus{
  outline: none;
}

.item {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.adminHover:hover {
 
  cursor: pointer;
  transform: scale(1.01);
}


.itemsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;
  height: calc(100dvh - (var(--navbar-height) + var(--btn-wrapper-height) + 50px));
  overflow: scroll ;
}

.item {
  display: flex;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 8px;  
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
 
  align-items: center;
  justify-content: center; 
}

.itemImage {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 15px;
}

.itemDetails {
  
  flex: 1;
}

.itemName {
  font-size: 16px;
  margin: 2px 0 5px;
}

.itemDescription {
  font-size: 13px;
  color: #555;
  text-transform: capitalize;
  margin: 0 0 10px;
}

.itemFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.itemPrice {
  font-size: 15px;
  font-weight: 600;
}

.quantityControl {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantityButton {
  width: 30px;
  height: 30px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  margin: 0 10px;
}

.quantity {
  font-size: 16px;
  font-weight: 600px;
}



.inCart {
  background-color: #e0ffe0; /* Example background color for items in cart */
}

.checkoutContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.qrContainer{
  height: calc(100dvh - ( var(--navbar-height) + var(--btn-wrapper-height) + 165px));
  
}

.menuItems{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}


.checkboxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
 
}

.checkboxGroup {
  display: flex;
  align-items: center;
  margin-left: 10px;
}



.checkboxLabel {
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 16px;
  color: #666666;
}

.generateQrButton {
  margin-top: 20px;
  padding: 5px 10px;
  background-color: #ff5a5f;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.generateQrButton:hover {
  background-color: #ff3a3f;
}


.generateQrButton:focus {
  outline: none;
}