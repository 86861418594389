.customerParent {
  background: "#ebebeb";

  height: "100dvh";
  overflow: "hidden";
}

.customerWrapper {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}

@media (min-width: 900px) {
  .customerWrapper {
    width: 60%;
    background: #f5f5f5;
  }
}
