.orderDetailsWrapper {
    width: 60%;
   margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  transform: translateY(100%);
  animation: slideUp 0.3s forwards;
}


.detailsContent{
  overflow: auto;
  max-height: 70vh;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.detailsContent div {
  display: flex;
  justify-content: space-between;
  padding: 2px 0;
  border-bottom: 1px solid #f1f1f1;
}

.detailsContent div:last-child {
  border-bottom: none;
}

@media(max-width:900px){
  .orderDetailsWrapper{
    width: 100%;
  }
}
