.container {
  padding: 20px;
  overflow: auto;

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.header h1 {
  margin: 0;
  font-size: 20px;
}

.header h2 {
  margin: 0;
  font-size: 16px;
  color: green;
}

.statement table {
  width: 100%;
  border-collapse: collapse;
}

.statement th, .statement td {
  border: 1px solid #ddd;
  padding: 8px;
}

.statement th {
  background-color: #f2f2f2;
}


@media (max-width: 769px) {
  .header h2 {
  font-size: 14px;
}

.header h1 {
 
  font-size: 14px;
}

.statement th, .statement td{
  padding: 4px;
  font-size: 12px;
}
}