


.bodyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0% 3%;
  height: calc(100dvh - var(--navbar-height) - 50px);
  overflow: auto;
}


.header {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  
}

.header h2{
  font-size: 24px;
  color: #444444;
  margin: 0 5px;
  font-weight: 600;
}


.itemsContainer {
  margin-top: 10px;
  width: 100%;
  background: #fff;
  padding: 2%;
  border-radius: 5px;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.itemImage {
  width: 50px;
  height: 50px;
  border-radius: 8px;
 
}

.itemDetails {  
 display: flex;
 justify-content: space-between;
 flex: 1;
 padding-left: 10px;
 margin-left: 10px;
}

.itemName {
  font-size: 16px;
  margin: 0;
}

.itemPrice {
  color: #666666;
  font-size: 14px;
  margin:  0;
}

.quantityControl {
  display: flex;
  align-items: center;
}



.quantityButton {
  width: 30px;
  height: 30px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  margin: 0 5px;
}


.quantity {
  padding: 5px;
}

.billDetails {
  width: 100%;
 
 
 
  border-radius: 5px;  
  margin-top: 15px;
}

.billDetails h3{
  font-size: 20px;
  font-weight: 600;
  color: #444444;
}

.billItems{
  background: #fff;
  padding: 2%;
  border-radius: 5px;
}

.billItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 15px;
  color: #666666;
}

.billTotal {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-top: 10px;
}


.itemRemark {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}
.qrContainer{
  height: calc(100dvh - ( var(--navbar-height) + var(--btn-wrapper-height) + 50px));
}

