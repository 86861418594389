.orderContainer {
  display: flex;
}
.menuListContainer {
  width: 60%;
  border-right: 1px dotted grey;
  padding: 10px;
}
.checkoutWrapper {
  background: #f5f5f5;
  width: 100%;
  height: 100dvh;
  border: 1px solid #ccc;
}

.itemRemark {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navbar-height);
  padding: 2%;
  background-color: #fff;
  position: sticky;
  top: 0;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0% 2%;
  width: 40%;
  height: calc(100dvh - 135px);
  overflow: auto;
}

.hamburgerIcon {
  height: 35px;
  width: 35px;
}

.header {
  display: flex;
    width: 100%;
    align-items: center;
    margin-top: 10px;
    position: sticky;
    top: 0;
    background: #f5f5f5;
    padding: 10px 0;
}

.header h2 {
  font-size: 24px;
  color: #444444;
  margin: 0 5px;
  font-weight: 600;
}

.itemsContainer {
  margin-top: 10px;
  width: 100%;
  background: #fff;
  padding: 2%;
  border-radius: 5px;
  max-height: 40vh;
  /* min-height: 25vh; */
  overflow: auto;
  
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.itemImage {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.itemDetails {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-left: 10px;
  margin-left: 10px;
}

.itemName {
  font-size: 16px;
  margin: 0;
}

.itemPrice {
  color: #666666;
  font-size: 14px;
  margin: 0;
}

.quantityControl {
  display: flex;
  align-items: center;
}

.quantityButton {
  width: 30px;
  height: 30px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  margin: 0 5px;
}

.quantity {
  padding: 5px;
}

.billDetails {
  width: 100%;
  position: sticky;
  bottom: 0;
  border-radius: 5px;
  margin-top: 15px;
}

.billDetails h3 {
  font-size: 20px;
  font-weight: 600;
  color: #444444;
  margin-bottom: 0;
  padding: 10px 0;
  background: #f5f5f5;
}

.billItems {
  background: #fff;
  padding: 2%;
  border-radius: 5px;
}

.billItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 15px;
  color: #666666;
}

.billTotal {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-top: 10px;
}


.checkoutButton {
  padding: 10px 20px;
   background: linear-gradient(135deg,  #ff4c29 1%,#eb1c24 100%);
  color: white;
  border: none;
  border-radius: 0;
  /* width: 40%; */
 text-decoration: none;
  text-align: center;
 
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}


.checkoutButton:hover {
  background-color: #e03e00;
  color: #fff;
}
.checkoutButton:focus{
  outline:none;
}


@media (min-width: 900px) {
  .checkoutWrapper {
    width: 60%;
    background: #f5f5f5;
  }
}
