.actionBtn{
    border: none;
    color: #4B589F;
    background: none;
    font-size: 12px;
    margin: 1px 0px;

}

.actionBtn:hover{
    background: #ff4c29;
    color: #fff;
}
.cancelButton {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    color: var(--red);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
  }
  
  .icon {
    width: 14px;
    height: 14px;
    /* color: white; */
    transition-duration: 0.3s;
  }
  
  .cancelButton:hover {
    width: 100px;
    border-radius: 5px;
    transition-duration: 0.3s;
    background-color: var(--red);
    align-items: center;
  }
  .cancelButton:focus{
    outline: none;
  }
  .cancelButton:hover .icon {
    transition-duration: 0.3s;
    transform: translateY(-200%);
  }
  
  .cancelButton::before {
    position: absolute;
    bottom: -20px;
    content: "Cancel Order";
    color: white;
    font-size: 0px;
  }
  
  .cancelButton:hover::before {
    font-size: 13px;
    opacity: 1;
    bottom: unset;
    transition-duration: 0.3s;
  }
  
  .submitButton {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    color: var(--green);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
  }
  
  .submitIcon {
    width: 17px;
    height: 17px;
    /* color: white; */
    transition-duration: 0.3s;
  }
  
  .submitButton:hover {
    width: 110px;
    border-radius: 5px;
    transition-duration: 0.3s;
    background-color: var(--green);
    align-items: center;
  }
  .submitButton:focus{
    outline: none;
  }
  .submitButton:hover .icon {
    transition-duration: 0.3s;
    transform: translateY(-200%);
  }
  
  .submitButton::before {
    position: absolute;
    bottom: -20px;
    content: "Order Completed";
    color: white;
    font-size: 0px;
  }
  
  .submitButton:hover::before {
    font-size: 13px;
    opacity: 1;
    bottom: unset;
    transition-duration: 0.3s;
  }
  